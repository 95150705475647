<template>
    <div v-if="modalShow">
        <b-modal 
            id="abrir-modal-pdf-adesao" 
            hide-header 
            hide-footer 
            centered 
            v-model="modalShow"
            :style="{ height: '70vh', width: '70vw' }" 
            size="xl"
            no-close-on-esc
            no-close-on-backdrop
            
        >
            <div class="d-flex flex-column align-items-center justify-center-center my-2 text-center container-modal ">
                <vue-pdf-app 
                    :style="{ height: '100%', width: '100%' }" 
                    :config="config"
                    :pdf=pdf.blob 
                    theme="dark" 
                />
                <div class="d-flex mt-3">
                    <a @click.prevent="downloadTermo" class="mr-1 btn custom-blue">
                        <feather-icon size="18" icon="DownloadIcon" class="mr-50 text-white" />
                        <span class="align-middle text-white">Download Termo</span>
                    </a>
                    <button class="mr-1 btn custom-outline-blue" @click.prevent="fecharModal">Fechar</button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { BModal, BFormTextarea } from "bootstrap-vue";

export default {
    components: {
        BModal,
        BFormTextarea,
    },
    props: {
        modalShow: {
            required: true,
            type: Boolean
        },
        pdf: {
            required: true
        },
    },
    data() {
        return {
            config: {
                toolbar: false
            }
        };
    },
    methods: {
        fecharModal() {
            this.$emit('fechar');
        },
        downloadTermo() {
            const link = document.createElement('a');
            link.href = this.pdf.blob;
            link.download = this.pdf.nome;
            link.click();
            URL.revokeObjectURL(link.href);
        }
    },
};
</script>

<style lang="scss" scoped >
.container-modal {
    width: 90vw;
    height: 70vh;
}

.text-white {
    color: white;
}

.border-modal {
    border: 2px solid #2772C0;
}

</style>
